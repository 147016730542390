import React, { useReducer, useEffect } from "react";

import { validate } from "../../util/validators";
import "./Input.css";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.value || "",
    isTouched: false,
    isValid: props.valid || false,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  const element = () => {
    if (props.element === "input") {
      return (
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          style={{ marginTop: 0 }}
          className="form-control"
        />
      );
    } else if (props.element === "select") {
      return (
        <select
          id={props.id}
          name={props.name}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          style={{ marginBottom: 16 }}
          className="browser-default custom-select"
        >
          {props.options.map((option) => {
            return <option value={option}>{option}</option>;
          })}
        </select>
      );
    } else {
      return (
        <textarea
          id={props.id}
          rows={props.rows || 3}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          style={{ marginTop: 0 }}
          className="form-control"
        />
      );
    }
  };
  /*props.element === "input" ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    ) : (
      <textarea
        id={props.id}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    );*/

  return (
    <div
      className={` ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      }`}
    >
      <label className="grey-text" htmlFor={props.id}>
        {props.label}
      </label>
      {element()}
      {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    </div>
  );
};

export default Input;
